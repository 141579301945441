const firebaseConfig = {
  apiKey: 'AIzaSyAcsqczxY6IESuVyTVWom1MkZNtl4B5b-k',
  authDomain: 'fir-park-bada6.firebaseapp.com',
  projectId: 'fir-park-bada6',
  storageBucket: 'fir-park-bada6.appspot.com',
  messagingSenderId: '732859604255',
  appId: '1:732859604255:web:6f398532fabd7328dba42a'
};

const config = {
  firebaseConfig,
  firebaseClientEmail:
    'firebase-adminsdk-trcke@fir-park-bada6.iam.gserviceaccount.com',
  colors: {
    primary: '#709bff'
  },
  name: 'DemoPark',
  logo: 'adflow-logo.png',
  title: 'DemoPark',
  productionURL: 'https://demo-park.vercel.app',
  companyURL: 'https://www.demopark.demo',
  supportNumber: '+46 123 456 7890',
  supportEmail: 'info@demopark.se',
  adress: 'Adress 1 Country'
};

module.exports = config;
